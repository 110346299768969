import Bugsnag from "@bugsnag/js"
import {stringifyError} from "src/components/ErrorBox"
import {AUTH_URL} from "../../../../config"
import generateHeader from "../../generateHeader"

let isLoggingOut = false

export default async function (token: string, deviceId: string) {
  if (isLoggingOut) {
    return
  }

  try {
    isLoggingOut = true
    await fetch(`${AUTH_URL}/auth/logout`, {
      method: "POST",
      headers: generateHeader({string1: deviceId, string2: token}),
      body: JSON.stringify({deviceId}),
    })
  } catch (e: unknown) {
    Bugsnag.notify(
      {name: "LogoutError", message: stringifyError(e as Error)},
      (error) => {
        error.severity = "info"
      },
    )
  } finally {
    isLoggingOut = false
  }
}
